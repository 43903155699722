import {
  BenefitDocumentDto,
  EmployerDocumentDto,
  GetEmployerDto,
} from '@zorro/clients';
import { Space, Stack, Text, brand } from '@zorro/zorro-ui-design';
import _noop from 'lodash/noop';

import { ModalFooter } from '../SimpleActionModal';
import { useMonolithMutation } from '../hooks';

export type DeleteDocumentConfirmationFormProps = {
  onClose?: () => void;
  onSubmit?: () => void;
  selectedDocument?: EmployerDocumentDto | BenefitDocumentDto;
  employer?: GetEmployerDto;
  isBenefitDoument?: boolean;
};

export const DeleteDocumentConfirmationForm = ({
  selectedDocument,
  employer,
  onClose = _noop,
  onSubmit = _noop,
  isBenefitDoument = false,
}: DeleteDocumentConfirmationFormProps) => {
  const { mutate: removeBenefitDocumentMutation } = useMonolithMutation({
    method: 'benefitDocumentsControllerRemoveBenefitDocument',
    successMessage: 'Document deleted',
  });

  const { mutate: deleteDocumentMutation } = useMonolithMutation({
    method: 'employerDocumentControllerDeleteDocument',
    successMessage: 'Document deleted!',
  });
  const documentName = selectedDocument?.fileName || null;

  const deleteBenefitDocument = async () => {
    if (selectedDocument) {
      await removeBenefitDocumentMutation([selectedDocument.id]);
    }
  };

  const deleteDocument = async () => {
    if (selectedDocument && employer) {
      await deleteDocumentMutation([employer.id, selectedDocument.id]);
    }
  };

  const handleSubmit = async () => {
    if (isBenefitDoument) {
      deleteBenefitDocument();
    }
    deleteDocument();
    onSubmit();
  };

  return (
    <Stack>
      <Text fw={500}>
        Are you sure you want to delete this document?
        <Space h="xs" />
        <Text
          fw={500}
          c={brand.zorroGray400}
          style={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
        >
          {documentName}
        </Text>
      </Text>
      <Space h="xl" />
      <ModalFooter
        submitText="Delete document"
        submitVariant="primary-red"
        onSubmit={handleSubmit}
        onClose={onClose}
      />
    </Stack>
  );
};
